<template lang="pug">
.thank-you-message(:class="[white ? 'text-white': 'text-black']")
  .text-success
    svg(
      xmlns='http://www.w3.org/2000/svg',
      xmlns:xlink='http://www.w3.org/1999/xlink',
      fill='currentColor',
      viewBox='0 0 16 16',
      width='70',
      height='70'
    )
      path(
        d='M13.854 3.646a.5.5 0 010 .708l-7 7a.5.5 0 01-.708 0l-3.5-3.5a.5.5 0 11.708-.708L6.5 10.293l6.646-6.647a.5.5 0 01.708 0z'
      )
  h5.mb-2 We have received your message.
  h4.mb-2(v-if="title||name") Dear {{ title && title }} {{ name && name }},
  slot(name='thankYouMessage')
  p If you have any other queries, please contact us on
    a.fw-bold(:href='contactLink') &nbsp;{{ contactNumber }}.
  br
  p.mb-1 Yours sincerely,
  h6.mb-5 {{ signature }}
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  name: {
    type: String,
    default: '', // Default value for name
  },
  title: {
    type: String,
    default: '', // Default value for title
  },
  signature: {
    type: String,
    default: '', // Default value for signature
  },
  contactNumber: {
    type: String,
    default: '', // Default value for contactNumber
  },
  white: {
    type: Boolean,
    default: false, // Already provided
  },
});

const contactLink = computed(() => `tel:${props.contactNumber}`);
</script>

<style lang="scss" scoped>
.thank-you-message {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  min-height: 500px;
  text-align: left;
  .message__check {
    font-size: 6rem;
  }
}
</style>
